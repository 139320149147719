import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getAllTaskEp as endpoint } from '../apiEndPoints';
import { IGetTaskProps } from "../ITask";


const GetAllTaskAPI = async ({ header }: IGetTaskProps) => {
  const url = endpoint()
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }

  return fetchInterceptor(url, options)

 
};

export default GetAllTaskAPI;
