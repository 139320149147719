
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';
import { getAllInvestigationEp as endpoint } from '../apiEndpoints';
import {  IGetInvestigationProps } from '../Iinvestigation';


const GetAllInvestigationAPI = async ({ header }: IGetInvestigationProps) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }

    return fetchInterceptor(url, options)

};

export default GetAllInvestigationAPI;