import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddInvestigationFrom from "./AddInvestigationFrom";
import { IGetInvestigation } from "../../../../api/Investigation/Iinvestigation";

const AddInvestigation = () => {
  const location = useLocation();
  const data = location.state as IGetInvestigation;
  const title = data ? "Edit Investigation" : "Add Investigation";

  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title.toUpperCase()}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link
                to="/investigation"
                className="text-addButton font-bold text-sm"
              >
                Investigation /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
          <div className="my-2"></div>
        </Typography>
        {data ? (
          <AddInvestigationFrom
            id={data._id}
            investigation={data.investigation}
          />
        ) : (
          <AddInvestigationFrom />
        )}
      </Paper>
    </div>
  );
};

export default AddInvestigation;
