import { FC, useEffect, useState } from "react";
import useGetState from "../../../../Hooks/State/useGetState";
import toast, { Toaster } from "react-hot-toast";
import { Field, Form } from "react-final-form";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { header } from "../../../../context/constant";
import useInvestigation from "../../../../Hooks/Investigation/useInvestigation";
import useGetEmployees from "../../../../Hooks/Team/useGetEmployes";
import * as yup from "yup";
import { setIn } from "final-form";
import { IAddTask } from "../../../../api/Task/ITask";
import AddTaskService from "../../../../api/Task/AddTask/AddTaskService";
import { useNavigate } from "react-router-dom";

import useGetRMList from "../../../../Hooks/RM/useGetRMList";
import useGetAreas from "../../../../Hooks/Area/useGetAreas";

const AddTaskForm = () => {
  const rm = useGetRMList({ header, role: "relationship manager" });
  const title = "Add Task";

  const [selectedAreaId, setSelectedAreaId] = useState();
  const [selectedInvestigationId, setSelectedInvestigationId] = useState();
  const [selectedRmId, setSelectedRmId] = useState();
  const navigate = useNavigate();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const areaData = useGetAreas({ header });
  const investigationData = useInvestigation();
  const em = useGetEmployees({ header });
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    area: yup.string().required("Area is required"),
    investigation: yup.string().required("Investigation is required"),
    teamName: yup.string().required("Team Name is required"),
    fullName: yup.string().required("rm is required"),
  });

  const validate = validateFormValues(validationSchema);

  const callAddtaskApi = async (data: IAddTask) => {
    try {
      const res = await AddTaskService(data);
      if (res.success) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const onSubmit = async () => {
    const payload = {
      employeeId: selectedEmployeeId!,
      investigationId: selectedInvestigationId!,
      areaId: selectedAreaId!,
      relationshipManagerId: selectedRmId!,
      header,
    };
    await callAddtaskApi(payload);
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field name="area">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="area"
                          value={input.value !== undefined ? input.value : null}
                          options={areaData || []}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.area || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.area);
                            setSelectedAreaId(newValue._id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Area"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="investigation">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="investigation"
                          value={input.value !== undefined ? input.value : null}
                          options={investigationData || []}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.investigation || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.investigation);
                            setSelectedInvestigationId(newValue._id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Investigation "
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="teamName">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="teamName"
                          value={input.value !== undefined ? input.value : null}
                          options={em[0] || []}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.teamName || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.teamName);
                            setSelectedEmployeeId(newValue._id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Employee "
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="fullName">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="fullName"
                          value={input.value !== undefined ? input.value : null}
                          options={rm[0] || []}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.fullName || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.fullName);
                            setSelectedRmId(newValue._id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select RM "
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddTaskForm;
