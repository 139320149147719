import { Button, Grid, TextField } from "@mui/material";
import { FC } from "react";
import { Field, Form } from "react-final-form";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import { setIn } from "final-form";
import { header, SafeKaroUser } from "../../../../context/constant";
import { useNavigate } from "react-router-dom";

import {
  IAddInvestigation,
  IEditInvestigation,
} from "../../../../api/Investigation/Iinvestigation";
import AddInvestigationService from "../../../../api/Investigation/AddInvestigation/AddInvestigationService";
import EditInvestigationService from "../../../../api/Investigation/EditInvestigation/EditInvestigationService";
interface InvestigationPros {
  id?: string;
  investigation?: string;
}

const AddInvestigationFrom: FC<InvestigationPros> = ({ id, investigation }) => {
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const title = id ? "Edit Investigation" : "Add Investigation";
  const navigate = useNavigate();
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    investigation: yup
      .string()
      .required("investigation Name is required")
      .min(1, "investigation must be at least 1 character")
      .max(100, "investigation cannot exceed 100 characters"),
  });

  const validate = validateFormValues(validationSchema);

  const callAddInvestigationApi = async (data: IAddInvestigation) => {
    try {
      const res = await AddInvestigationService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const callEditInvestigationApi = async (data: IEditInvestigation) => {
    try {
      const res = await EditInvestigationService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const onSubmit = async (data: any) => {
    const { investigation } = data;

    if (id) {
      let payload: IEditInvestigation = { header, id, investigation };
      callEditInvestigationApi(payload);
    } else {
      const payload: IAddInvestigation = {
        header,
        investigation,
        createdBy: userData.name,
      };
      callAddInvestigationApi(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ id, investigation }}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Field name="investigation">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter investigation"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddInvestigationFrom;
