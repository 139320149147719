import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { postReqPayload } from "../OccupancyTypes";
import { postOccupancyEp as endpoint } from "../ApiEndPoints";

const AddOccupancyAPI = async ({
    occupancy,createdBy,header
}: postReqPayload) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify({
            occupancy,createdBy
        }),
    }

    return fetchInterceptor(url, options)


};

export default AddOccupancyAPI;