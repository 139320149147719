import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { IIndustry } from "./IIndustry";
import AddIndustryForm from "./AddIndustryForm";

const AddIndustry = () => {
  const loc = useLocation();
  const data = loc.state as IIndustry;
  const title = data? "Edit Industry":"Add Industry";
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title.toUpperCase()}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link to="/industry" className="text-addButton font-bold text-sm">
                Industry /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
          <div className="my-2"></div>
        </Typography>
        {
          data?  <AddIndustryForm propsData={data}/>:<AddIndustryForm/>
        }
       
      </Paper>
    </div>
  );
};

export default AddIndustry;
