
import { GetAllProduct } from "../getProductsTypes";
import GetAllProductAPI from "./GetAllProductAPI";
const GetAllProductServices = async ({ header }: GetAllProduct): Promise<any> => {
  try {
    const res = await GetAllProductAPI({
      header: header
    })
    return res;
  } catch (error) {
    throw error
  }

};

export default GetAllProductServices;