import { useEffect, useRef, useState } from "react"
import { IGetInvestigation } from "../../api/Investigation/Iinvestigation"
import { header } from "../../context/constant";
import GetAllInvestigationService from "../../api/Investigation/GetAllInvestigation/GetAllInvestigationService";
const useInvestigation = () => {
    const [investigations, setInvestigation] = useState<IGetInvestigation[]>();
    const isLoading = useRef(true);
    useEffect(() => {
        if (isLoading.current) {
            GetAllInvestigationService({ header })
                .then((apiResponse) => {
                    isLoading.current = false;
                    setInvestigation(apiResponse.data!);
                })
                .catch((res) => {
                    console.error(res.status);
                });
        }
        // eslint-disable-next-line
    }, [header, isLoading]);

    return investigations;
}

export default useInvestigation