import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { SafeKaroUser } from "../../../../context/constant";
import { setIn } from "final-form";
import { header } from "../../../../context/constant";
import toast, { Toaster } from "react-hot-toast";
import { Field, Form } from "react-final-form";
import { Button, Grid, TextField } from "@mui/material";
import { IIndustry } from "./IIndustry";
import {
  IAddIndustry,
  IEditIndustry,
} from "../../../../api/Industry/IIndustry";
import EditIndustryService from "../../../../api/Industry/EditIndustry/EditIndustryService";
import AddIndustryService from "../../../../api/Industry/AddIndustry/AddIndustryService";
interface AddFromPros {
  propsData?: IIndustry;
}
const AddIndustryForm: FC<AddFromPros> = ({ propsData }) => {
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const navigate = useNavigate();
  const title = propsData ? "Edit Industry" : "Add Industry";

  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    industry: yup
      .string()
      .required("Industry Name is required")
      .min(1, "Industry must be at least 1 character")
      .max(100, "Industry cannot exceed 100 characters"),
  });

  const validate = validateFormValues(validationSchema);

  const callEditIndustry = async (data: IEditIndustry) => {
    try {
      const res = await EditIndustryService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const callAddIndustry = async (data: IAddIndustry) => {
    const res = await AddIndustryService(data);
    if (res.status.toLowerCase() === "success") {
      navigate(-1);
    }
    try {
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const onSubmit = (obj: any) => {
    const { industry } = obj;
    if (propsData && propsData._id) {
      const payload: IEditIndustry = {
        id: propsData._id,
        industry,
        header,
      };
      callEditIndustry(payload);
    } else {
      const payload: IAddIndustry = {
        industry,
        header,
        createdBy: userData.name,
      };
      callAddIndustry(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ industry: propsData?.industry }}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Field name="industry">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Industry Name"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddIndustryForm;
