import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
const CompareBrokerPaymentExcel: React.FC = () => {
  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      ["policyNumber", "payInCommission"],
      ["xyz1234324234", "323124234"],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "sample.xlsx");
  };
  return (
    <Tooltip title="Download Sample Excel">
      <button
        className="md:w-10 md:h-10 h-4 w-4 bg-[#027aae] shadow-sm rounded flex justify-center items-center text-white"
        onClick={handleDownload}
      >
        <FileDownloadOutlinedIcon className="md:w-6 md:h-6 h-3 w-3" />
      </button>
    </Tooltip>
  );
};
export default CompareBrokerPaymentExcel;
