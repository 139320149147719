export const createInvestigationEp = () => {
    return ('/api/non-motor/investigation')
}
export const getAllInvestigationEp = () => {
    return ('/api/non-motor/investigation')
}
export const getInvestigationByIdEp = (investigationId: string) => {
    return (`/api/non-motor/investigation/${investigationId}`)
}
export const editInvestigationByIdEp = (investigationId: string) => {
    return (`/api/non-motor/investigation/${investigationId}`)
}
export const deleteInvestigationByIdEp = (investigationId: string) => {
    return (`/api/non-motor/investigation/${investigationId}`)
}
