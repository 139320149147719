

import { IGetTaskProps } from "../ITask";
import GetAllTaskAPI from "./GetAllTaskAPI";

const GetAllTaskService = async ({ header }: IGetTaskProps): Promise<any> => {
    try {
        const res = await GetAllTaskAPI({
            header: header,
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetAllTaskService;