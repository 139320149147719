import { editReqPayload } from "../NatureOfWorkTypes";
import EditNatureOfWorkAPI from "./EditNatureOfWorkAPI";
const EditNatureOfWorkService = async ({
    natureOfWork, updatedBy, header, id
}: editReqPayload): Promise<any> => {
    try {
        const res = await EditNatureOfWorkAPI({
            header: header,
            natureOfWork, updatedBy, id
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditNatureOfWorkService;