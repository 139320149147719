import { Paper, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AddTaskForm from "./AddTaskForm";
import { ITask } from "../../../../api/Task/ITask";
import EditTaskForm from "./EditTaskForm";

const AddTask = () => {
  const taskData = useLocation().state as ITask;

  const title = taskData ? "Edit Task" : "Add Task";
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title.toUpperCase()}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link to="/task" className="text-addButton font-bold text-sm">
                Task /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
          <div className="my-2">
            {taskData ? (
              <EditTaskForm
                id={taskData._id}
                state={taskData.state}
                city={taskData.city}
                cityId={taskData.cityId}
                investigation={taskData.investigation!}
                area={taskData.area}
                areaId={taskData.areaId}
                investigationId={taskData.investigationId!}
                teamName={taskData.employee!}
                employeeId={taskData.employeeId!}
                rmId={taskData.relationshipManagerId!}
              />
            ) : (
              <AddTaskForm />
            )}
          </div>
        </Typography>
      </Paper>
    </div>
  );
};

export default AddTask;
