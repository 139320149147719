import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editTaskEp as endPoint } from "../apiEndPoints";
import { IEditTask } from "../ITask";


const EditTaskAPI = async ({ header, id,investigationId,areaId,employeeId,relationshipManagerId ,isActive}: IEditTask) => {
  const url = endPoint(id)
  const options: FetchOptions= {
    method: "PUT",
    headers: header,
    body: JSON.stringify({
      investigationId,areaId,employeeId,relationshipManagerId,isActive
    }),
  }
  return fetchInterceptor(url, options)

};

export default EditTaskAPI;