import { IAddIndustry } from "../IIndustry";
import AddIndustryAPI from "./AddIndustryAPI";


const AddIndustryService = async ({ header, createdBy, industry }: IAddIndustry): Promise<any> => {
    try {
        const res = await AddIndustryAPI({
            header, createdBy, industry
        });
        return res;
    } catch (error) {
        throw error
    }
};

export default AddIndustryService;