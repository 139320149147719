import fetchInterceptor, {
  FetchOptions,
} from "../../../utils/fetchInterceptor ";
import { createInvestigationEp as endpoint } from "../apiEndpoints";
import { IAddInvestigation } from "../Iinvestigation";

const AddInvestigationAPI = async ({
  header,
  investigation,
  createdBy,
}: IAddInvestigation) => {
  const url = endpoint();
  const options: FetchOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      header,
      investigation,
      createdBy,
    }),
  };

  return fetchInterceptor(url, options);
};

export default AddInvestigationAPI;
