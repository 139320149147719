import { FC } from "react";
import { NatureOfWorkProps } from "../NatureOfWorkType";
import { SafeKaroUser } from "../../../../context/constant";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import {
  editReqPayload,
  postReqPayload,
} from "../../../../api/NatureOfWork/NatureOfWorkTypes";
import EditNatureOfWorkService from "../../../../api/NatureOfWork/EditNatureOfWork/EditNatureOfWorkService";
import AddNatureOfWorkService from "../../../../api/NatureOfWork/AddNatureOfWork/AddNatureOfWorkService";
import { useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Button, Grid, TextField } from "@mui/material";
import { header } from "../../../../context/constant";
import { setIn } from "final-form";
const AddNatureOfWorkForm: FC<NatureOfWorkProps> = ({ propsData }) => {
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const title = propsData ? "Edit NatureOfWork" : "Add NatureOfWork";
  const navigate = useNavigate();
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    natureOfWork: yup
      .string()
      .required("natureOfWork Name is required")
      .min(1, "natureOfWork must be at least 1 character")
      .max(100, "natureOfWork cannot exceed 100 characters"),
  });

  const validate = validateFormValues(validationSchema);

  const callAddNatureOfWorkApi = async (data: postReqPayload) => {
    try {
      const res = await AddNatureOfWorkService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const callEditNatureOfWorkApi = async (data: editReqPayload) => {
    try {
      const res = await EditNatureOfWorkService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const onSubmit = async (data: any) => {
    const { natureOfWork } = data;
    if (propsData) {
      const payload: editReqPayload = {
        natureOfWork,
        updatedBy: userData.name,
        id: propsData._id,
        header,
      };
      callEditNatureOfWorkApi(payload);
    } else {
      const payload: postReqPayload = {
        natureOfWork,
        createdBy: userData.name,
        header,
      };
      callAddNatureOfWorkApi(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ natureOfWork: propsData?.natureOfWork }}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Field name="natureOfWork">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Nature Of Work"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddNatureOfWorkForm;
