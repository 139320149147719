import { useEffect, useRef, useState } from "react";
import { GetAccountProps } from "../../api/Account/getAccountTypes";
import { IArea } from "../../components/Admin/Area/IArea";
import GetAllAreasServices from "../../api/Area/GetAllAreas/GetAllAreasServices";


const useGetAreas = ({ header }: GetAccountProps) => {
    const [area, setArea] = useState<IArea[]>();
    const isLoading = useRef(true);
    useEffect(() => {
        if (isLoading.current) {
            GetAllAreasServices({ header })
                .then((apiResponse) => {
                    isLoading.current = false;
                    setArea(apiResponse.data);
                })
                .catch((res) => {
                    console.error(res.status);
                });
        }
    }, [header, isLoading]);

    return area;
};

export default useGetAreas;
