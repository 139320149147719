import { postReqPayload } from "../OccupancyTypes";
import AddOccupancyAPI from "./AddOccupancyAPI";

const AddOccupancyService = async ({
    occupancy, createdBy, header
}: postReqPayload): Promise<any> => {
    try {
        const res = await AddOccupancyAPI({
            header: header,
            occupancy, createdBy
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddOccupancyService;