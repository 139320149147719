import { IEditInvestigation } from "../Iinvestigation";
import EditInvestigationAPI from "./EditInvestigationAPI";


const EditInvestigationService = async ({ header, createdBy, investigation, id,isActive }: IEditInvestigation): Promise<any> => {
    try {
        const res = await EditInvestigationAPI({
            header, createdBy, investigation, id,isActive
        });
        return res;
    } catch (error) {
        throw error
    }
};

export default EditInvestigationService;