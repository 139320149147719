import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editReqPayload } from "../NatureOfWorkTypes";
import { editNatureOfWorkEp as endpoint } from "../ApiEndPoints";

const EditNatureOfWorkAPI = async ({
    natureOfWork, updatedBy, header, id
}: editReqPayload) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
            natureOfWork, updatedBy
        }),
    }
    return fetchInterceptor(url, options)
};

export default EditNatureOfWorkAPI;