import { postReqPayload } from "../NatureOfWorkTypes";
import AddNatureOfWorkAPI from "./AddNatureOfWorkAPI";

const AddNatureOfWorkService = async ({
    natureOfWork, createdBy, header
}: postReqPayload): Promise<any> => {
    try {
        const res = await AddNatureOfWorkAPI({
            header: header,
            natureOfWork, createdBy
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddNatureOfWorkService;