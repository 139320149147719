import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { setIn } from "final-form";
import React, { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import {
  ALLOWED_FILE_TYPES,
  DAY_FORMAT,
  Document,
  header,
  MAX_FILE_SIZE,
  SafeKaroUser,
} from "../../../../context/constant";
import { ICity } from "../../../Admin/City/ICity";
import useGetState from "../../../../Hooks/State/useGetState";
import GetCityByStateIdService from "../../../../api/City/GetCityByStateId/GetCityByStateIdService";
import { IArea } from "../../../Admin/Area/IArea";
import GetAreaByCityIdServices from "../../../../api/Area/GetAreaByCityId/GetAreaByCityIdServices";
import {
  IAddWorkmenProps,
  InitialValueProps,
  WorkmenCommunicationProps,
  WorkmenProps,
} from "../WorkmenCommunicationType";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { documentTypes } from "../../../Policy/IPolicyData";
import useGetProducts from "../../../../Hooks/Product/useGetProducts";
import useIndustry from "../../../../Hooks/Industry/useIndustry";
import { IIndustry } from "../../../Admin/industry/AddIndustry/IIndustry";
import dayjs from "dayjs";
import EditWorkmenCommunicationService from "../../../../api/WorkmenCommunication/EditWorkmenCommunication/EditWorkmenCommunicationService";
import AddWorkmenCommunicationService from "../../../../api/WorkmenCommunication/AddWorkmenCommunication/AddWorkmenCommunicationService";
import generateFormData from "../../uitils/generateFormData";
import { useNavigate } from "react-router-dom";
import { useGetNatureOfWork } from "../../../../Hooks/NatureOfWork/UseGetNatureOfWork";
import { INatureOfWork } from "../../../Admin/NatureOfWork/NatureOfWorkType";
const WorkmenCommunicationForm: FC<WorkmenCommunicationProps> = ({
  propsData,
}) => {
  const states = useGetState();
  const { data: natureOfWork, loading, error } = useGetNatureOfWork();
  const [productData] = useGetProducts({ header, category: "Non Motor" });
  const fireProduct = productData.filter((ele) => {
    return ele.productName?.toLowerCase() === "work men communication";
  });
  const [selectedCStateId, setSelectedCStateId] = useState(propsData?.CStateId);
  const [selectedCCityId, setSelectedCCityId] = useState(propsData?.cCityId);
  const [selectedRStateId, setSelectedRStateId] = useState(propsData?.rStateId);
  const [selectedRCityId, setSelectedRCityId] = useState(propsData?.rCityId);
  const [isMedicalExtension, setIsMedicalExtension] = useState(
    propsData?.medicalExtension
  );
  const [correspondenceArea, setCorrespondenceArea] = useState<IArea[]>();
  const [correspondenceCities, setCorrespondenceCities] = useState<ICity[]>([]);
  const [riskArea, setRiskArea] = useState<IArea[]>();
  const [riskCities, setRiskCities] = useState<ICity[]>([]);
  const [skilledPersonCount, setSkilledPersonCount] = useState(
    propsData?.NumberOfSkilledPerson || 0
  );
  const [semiSkilledPersonCount, setSemiSkilledPersonCount] = useState(
    propsData?.NumberOfSemiSkilledPerson || 0
  );
  const [unskilledPersonCount, setUnskilledPersonCount] = useState(
    propsData?.NumberOfUnskilledPerson || 0
  );
  const [skilledmonthlyWadges, setSkilledmonthlyWadges] = useState(
    propsData?.monthlyWadgeOfSemiskilledPerson || 0
  );
  const [semiskilledmonthlyWadges, setSemiSkilledmonthlyWadges] = useState(
    propsData?.monthlyWadgeOfSemiskilledPerson || 0
  );
  const [unskilledmonthlyWadges, setUnSkilledmonthlyWadges] = useState(
    propsData?.monthlyWadgeOfUnskilledPerson || 0
  );
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const [projectDuration, setProjectDuration] = useState(
    propsData?.projectDuration || 0
  );
  const [errors, setErrors] = useState<{ docName: string; file: string }[]>([
    { docName: "", file: "" },
  ]);
  const industryData = useIndustry();
  const [documents, setDocuments] = useState<Document[]>([
    { docName: "", file: "" },
  ]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleCStateChange = async (newValue: any) => {
    setSelectedCStateId(newValue._id);
    try {
      const res = await GetCityByStateIdService({
        header,
        stateId: newValue._id,
      });
      if (res.status === "success") {
        setCorrespondenceCities(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleCCityChange = async (newValue: any) => {
    setSelectedCCityId(newValue._id);
    try {
      const res = await GetAreaByCityIdServices({
        header,
        id: newValue._id,
      });
      if (res.status === "success") {
        setCorrespondenceArea(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const handleFileInputChange = (event: any, index: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      const newErrors = [...errors];

      if (!ALLOWED_FILE_TYPES.includes(fileType)) {
        // setErrorMessage("Invalid file type. Please upload an image or a PDF.");
        newErrors[index] = {
          ...newErrors[index],
          file: "Invalid file type. Please upload an image or a PDF.",
        };
        setErrors(newErrors);
      } else if (fileSize > MAX_FILE_SIZE) {
        //setErrorMessage("File size exceeds the maximum limit.");
        newErrors[index] = {
          ...newErrors[index],
          file: "File size exceeds the maximum limit",
        };
        setErrors(newErrors);
      } else {
        setErrorMessage("");
        const newDocuments = [...documents];
        newDocuments[index] = { ...newDocuments[index], file: file };
        setDocuments(newDocuments);

        // Clear the error if the file is valid
        if (newErrors[index]) {
          newErrors[index].file = "";
        }
        setErrors(newErrors);
      }
    }
  };

  const validateDocument = (document: Document, index: number) => {
    const isValidDocName = document.docName.trim() !== "";
    const isValidFile = document.file;
    validateField(index, "docName", document.docName);
    validateField(index, "file", document.file);
    return isValidDocName && isValidFile;
  };

  const validateField = (index: number, name: string, value: string) => {
    const newErrors = [...errors];
    if (name === "docName" || name === "file") {
      newErrors[index] = {
        ...newErrors[index],
        [name]: value === "" ? `${name} cannot be empty` : "",
      };
    }
    setErrors(newErrors);
  };

  const generateInitialData = () => {
    if (propsData) {
      const {
        industryDetails,
        fullName,
        email,
        phone,
        categoryId,
        categoryName,
        productTypeId,
        productTypeName,
        projectDuration,
        projectTitle,
        projectDescription,
        NumberOfSkilledPerson,
        NumberOfUnskilledPerson,
        NumberOfSemiSkilledPerson,
        monthlyWadgeOfSkilledPerson,
        monthlyWadgeOfSemiskilledPerson,
        monthlyWadgeOfUnskilledPerson,
        expenseOfSkilledPersonOnProject,
        expenseOfSemiskilledPersonOnProject,
        expenseOfUnskilledPersonOnProject,
        medicalExtension,
        isContributorStaffCover,
        cAddress,
        cPincode,
        cState,
        CStateId,
        cCity,
        cCityId,
        cAreaId,
        rAddress,
        rPincode,
        rState,
        rStateId,
        rCity,
        rCityId,
        startDate,
        endDate,
        gst,
        medicalExpense,
        hypothisiation,natureOfWorkId,natureOfWork
      } = propsData;

      const cArea: IArea = {
        _id: propsData.cAreaId ?? "",
        area: propsData.cArea ?? "",
        cityId: propsData.cCityId ?? "",
        cityName: propsData.cCity ?? "",
        stateId: propsData.CStateId ?? "",
        stateName: propsData.cState ?? "",
      };
      const natOfWork:INatureOfWork = {_id:natureOfWorkId||"",natureOfWork:natureOfWork||""}
      const rArea: IArea = {
        _id: propsData.rAreaId ?? "",
        area: propsData.rArea ?? "",
        cityId: propsData.rCityId ?? "",
        cityName: propsData.rCity ?? "",
        stateId: propsData.rStateId ?? "",
        stateName: propsData.rState ?? "",
      };
      const industry: IIndustry = {
        _id: propsData.industryId,
        industry: propsData.industry ?? "",
      };

      const result: InitialValueProps = {
        startDate: dayjs(startDate).toISOString(),
        endDate: dayjs(endDate).toISOString(),
        cArea,
        rArea,
        contractorStaff: isContributorStaffCover ? "YES" : "NO",
        industryDetails,
        fullName,
        email,
        phone,
        categoryId,
        categoryName,
        productTypeId,
        productTypeName,
        natureOfWork:natOfWork,
        projectDuration,
        projectTitle,
        projectDescription,
        NumberOfSkilledPerson,
        NumberOfUnskilledPerson,
        NumberOfSemiSkilledPerson,
        monthlyWadgeOfSkilledPerson,
        monthlyWadgeOfSemiskilledPerson,
        monthlyWadgeOfUnskilledPerson,
        expenseOfSkilledPersonOnProject,
        expenseOfSemiskilledPersonOnProject,
        expenseOfUnskilledPersonOnProject,
        medicalExtension: medicalExtension ? "YES" : "NO",
        isContributorStaffCover,
        cAddress,
        cPincode,
        cState,
        CStateId,
        cCity,
        cCityId,
        cAreaId,
        rAddress,
        rPincode,
        rState,
        rStateId,
        rCity,
        rCityId,
        industry,
        gst,
        medicalExpense,
        hypothisiation,
      };
      return result;
    }
  };

  const callEditWC = async (bodyData: any) => {
    try {
      const res = await EditWorkmenCommunicationService({
        id: propsData?._id!,
        workmenCommunication: generateFormData(bodyData),
      });
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const callAddWC = async (bodyData: any) => {
    try {
      const res = await AddWorkmenCommunicationService({
        workmenCommunication: generateFormData(bodyData),
      });
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const handleChangeDocumentName = (newValue: any, index: any) => {
    const updatedDocuments = documents.map((doc, i) =>
      i === index ? { ...doc, docName: newValue?.value! } : doc
    );
    setDocuments(updatedDocuments);
  };

  const handleClickAddDocument = () => {
    setDocuments([...documents, { docName: "", file: "" }]);
  };

  const handleClickDeleteDocument = (index: any) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== index)
    );
  };

  const handleRStateChange = async (newValue: any) => {
    setSelectedRStateId(newValue._id);
    try {
      const res = await GetCityByStateIdService({
        header,
        stateId: newValue._id,
      });
      if (res.status === "success") {
        setRiskCities(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleRCityChange = async (newValue: any) => {
    setSelectedRCityId(newValue._id);
    try {
      const res = await GetAreaByCityIdServices({
        header,
        id: newValue._id,
      });
      if (res.status === "success") {
        setRiskArea(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const calculateAmount = (monthlyWadge: number | string, person: number) => {
    const timeInMonth = Number(projectDuration) / 30;
    const wadgeMonthly = Number(monthlyWadge);
    const personCount = Number(person);
    return Math.round(timeInMonth * wadgeMonthly * personCount);
  };

  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };

  const validationSchema = yup.object().shape({
    cArea: yup.object().required("cArea is required"),
    rArea: yup.object().required("rArea is required"),
    industry: yup.object().required("Industry is required"),
    fullName: yup.string().required("Full Name is required").min(1).max(100),
    industryDetails: yup.string().optional(),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup
      .string()
      .required("Phone is required")
      .matches(/^\d{10}$/, "Phone number must be 10 digits"),
    startDate: yup.date().required("Start Date is required"),
    endDate: yup.date().required("End Date is required"),
    natureOfWork: yup.object().optional(),
    projectDuration: yup.string().optional(),
    projectTitle: yup.string().optional().max(200),
    projectDescription: yup.string().optional().max(200),
    NumberOfSkilledPerson: yup
      .number()
      .required("Number of Skilled Persons is required")
      .min(1, "Must have at least 1 skilled person"),
    gst: yup.string().required("GST is required").max(200),
    NumberOfUnskilledPerson: yup.number().nullable(),
    NumberOfSemiSkilledPerson: yup.number().nullable(),
    monthlyWadgeOfSkilledPerson: yup.string().nullable(),
    monthlyWadgeOfSemiskilledPerson: yup.string().nullable(),
    monthlyWadgeOfUnskilledPerson: yup.string().nullable(),
    expenseOfSkilledPersonOnProject: yup.string().nullable(),
    expenseOfSemiskilledPersonOnProject: yup.string().nullable(),
    expenseOfUnskilledPersonOnProject: yup.string().nullable(),
    medicalExtension: yup.string().optional(),
    isContributorStaffCover: yup.boolean().optional(),
    cAddress: yup.string().required("Current Address is required"),
    cPincode: yup
      .string()
      .required("correspondence Pincode is required")
      .matches(/^\d{6}$/, "Pincode must be 6 digits"),
    cState: yup.string().required("Current State is required"),
    cCity: yup.string().required("Current City is required"),
    rAddress: yup.string().optional(),
    rPincode: yup
      .string()
      .required("Risk Pincode is required")
      .matches(/^\d{6}$/, "Pincode must be 6 digits"),
    rState: yup.string().nullable(),
    rStateId: yup.string().nullable(),
    rCity: yup.string().optional(),
    rCityId: yup.string().nullable(),
    contractorStaff: yup.string().optional(),
  });

  const validate = validateFormValues(validationSchema);

  const onSubmit = async (data: any) => {
    if (!propsData) {
      const formValid = documents.every((doc, index) =>
        validateDocument(doc, index)
      );
      if (!formValid) {
        return;
      }
    }
    let {
      cArea,
      rArea,
      startDate,
      endDate,
      industry: I,
      contractorStaff,
      medicalExtension,
      medicalExpense
    } = data;
    const { categoryId, categoryName, _id, productName } = fireProduct[0];
    const {natureOfWork,_id:natureOfWorkId} =data.natureOfWork
    let payload: IAddWorkmenProps = {
      ...data,
      CStateId: selectedCStateId,
      productTypeId: _id,
      categoryName,
      categoryId,
      productTypeName: productName,
      cCityId: selectedCCityId,
      cArea: cArea.area,
      cAreaId: cArea._id,
      startDate: dayjs(startDate).format(DAY_FORMAT),
      endDate: dayjs(endDate).format(DAY_FORMAT),
      rStateId: selectedRStateId,
      rCityId: selectedRCityId,
      rAreaId: rArea._id,
      industry: I.industry,
      industryId: I._id,
      rArea: rArea.area,
      natureOfWorkId,
      natureOfWork,
      expenseOfSemiskilledPersonOnProject: calculateAmount(
        semiskilledmonthlyWadges,
        semiSkilledPersonCount
      ),
      expenseOfSkilledPersonOnProject: calculateAmount(
        skilledmonthlyWadges,
        skilledPersonCount
      ),
      expenseOfUnskilledPersonOnProject: calculateAmount(
        unskilledmonthlyWadges,
        unskilledPersonCount
      ),
      IsContributorStaffCover: contractorStaff === "YES" ? true : false,
      contractorStaff: contractorStaff === "YES" ? true : false,
      medicalExtension: medicalExtension === "YES" ? true : false,
      createdBy: userData.name,
      medicalExpense: Number(medicalExpense),
      NumberOfSemiSkilledPerson: semiSkilledPersonCount,
    };
    documents.forEach((doc: Document) => {
      if (doc.file) {
        payload[doc.docName] = doc.file;
      }
    });

    if (propsData) {
      callEditWC(payload);
    } else {
      callAddWC(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={generateInitialData()}
        render={({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="industry">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="industry"
                          value={input.value || null}
                          options={industryData}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.industry || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Industry"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="industryDetails">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Industry Details"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <span>Personal Details</span>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.FullName}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Name"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.Email}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Email"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.Phone}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Phone Number"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <span>Correspondence Address</span>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.CState}>
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.CState}
                          value={input.value !== undefined ? input.value : null}
                          options={states}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.state);
                            handleCStateChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select State"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              {selectedCStateId && correspondenceCities && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name={WorkmenProps.CCity}>
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.CCity}
                          value={input.value !== undefined ? input.value : null}
                          options={correspondenceCities}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.city || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.city);
                            handleCCityChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select City"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              {selectedCCityId && correspondenceArea && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name={WorkmenProps.CArea}>
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.CArea}
                          value={input.value !== undefined ? input.value : null}
                          options={correspondenceArea}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.area || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select area"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.CAddress}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Address"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.CPincode}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Pin Code"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <span>Risk Address</span>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.RState}>
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.RState}
                          value={input.value !== undefined ? input.value : null}
                          options={states}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.state);
                            handleRStateChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select State"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              {selectedRStateId && riskCities && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name={WorkmenProps.RCity}>
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.RCity}
                          value={input.value !== undefined ? input.value : null}
                          options={riskCities}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.city || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.city);
                            handleRCityChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select City"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              {selectedRCityId && riskArea && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name={WorkmenProps.RArea}>
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.RArea}
                          value={input.value !== undefined ? input.value : null}
                          options={riskArea}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.area || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select area"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.RAddress}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Address"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.RPincode}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Pin Code"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <span>Project Details</span>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="gst">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter GST"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.NatureOfWork}>
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="natureOfWork"
                          value={input.value || null}
                          options={natureOfWork}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.natureOfWork || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Nature of Work"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.ProjectDuration}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Project Project Duration(In Days)"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setProjectDuration(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Field name={WorkmenProps.ProjectTitle}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Project Title"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Field name={WorkmenProps.ProjectDescription}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Project Description"
                      type="text"
                      variant="outlined"
                      size="small"
                      rows={4}
                      multiline
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.NumberOfSkilledPerson}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="No of Skilled Person"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={skilledPersonCount}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setSkilledPersonCount(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.MonthlyWageOfSkilledPerson}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Monthly Wadge Skilled Person"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={skilledmonthlyWadges}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setSkilledmonthlyWadges(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.ExpenseOfSkilledPersonOnProject}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Expense Of Skilled Person On Project"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      value={calculateAmount(
                        skilledmonthlyWadges,
                        skilledPersonCount
                      )}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.NumberOfSemiskilledPerson}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="No of Semiskilled Person"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={semiSkilledPersonCount}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setSemiSkilledPersonCount(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.MonthlyWageOfSemiSkilledPerson}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Monthly Wadge Semiskilled Person"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={semiskilledmonthlyWadges}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setSemiSkilledmonthlyWadges(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.ExpenseOfSemiSkilledPersonOnProject}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Expense Of Semi Skilled Person On Project"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      value={calculateAmount(
                        semiskilledmonthlyWadges,
                        semiSkilledPersonCount
                      )}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.NumberOfUnskilledPerson}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="No of Unskilled Person"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={unskilledPersonCount}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setUnskilledPersonCount(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.MonthlyWageOfUnskilledPerson}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Monthly Wadge Unskilled Person"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={unskilledmonthlyWadges}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) => {
                        setUnSkilledmonthlyWadges(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.ExpenseOfUnskilledPersonOnProject}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Expense Of Unskilled Person On Project"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      value={calculateAmount(
                        unskilledmonthlyWadges,
                        unskilledPersonCount
                      )}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.MedicalExtension}>
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.MedicalExtension}
                          value={input.value || null}
                          options={["YES", "NO"]}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                            if (newValue === "YES") {
                              setIsMedicalExtension(true);
                            } else {
                              setIsMedicalExtension(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Medical Extension"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              {isMedicalExtension && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name={WorkmenProps.MedicalExpense}>
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="Enter Medical Amount"
                        type="number"
                        variant="outlined"
                        size="small"
                        className="rounded-sm w-full"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.ContractorStaff}>
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id={WorkmenProps.ContractorStaff}
                          value={input.value || null}
                          options={["YES", "NO"]}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contractor Staff Cover"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.StartDate}>
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name={WorkmenProps.EndDate}>
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="hypothisiation">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="hypothisiation"
                          value={input.value || null}
                          options={["YES", "NO"]}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Hypothecation"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item md={12} mt={2}>
                <Button variant="outlined" onClick={handleClickAddDocument}>
                  Add More Document
                </Button>
                <Typography variant="body1" gutterBottom mr={4}>
                  {"Image should be <= 2MB."}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </Grid>
                {documents.map((doc, index) => (
                  <Grid item key={index} md={12} xs={12}>
                    <Grid container spacing={2} mt={1}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Autocomplete
                          value={
                            documentTypes.find(
                              (option) => option.value === doc.docName
                            ) || null
                          }
                          //value={doc.docName}
                          onChange={(e, newValue) =>
                            handleChangeDocumentName(newValue!, index)
                          }
                          options={documentTypes}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="rounded-sm w-full"
                              size="small"
                              label="Select Document"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                        {errors[index]?.docName && (
                          <span>{errors[index].docName}</span>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <input
                          id={`file ${index}`}
                          type="file"
                          onChange={(e) => handleFileInputChange(e, index)}
                          style={{
                            border: "1px solid #c4c4c4",
                            padding: "5px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        />
                        {errors[index]?.file && (
                          <span style={{ color: "red" }}>
                            {errors[index].file}
                          </span>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        {documents.length !== 1 && (
                          <Tooltip title={"Delete Image"}>
                            <IconButton
                              color="primary"
                              aria-label={"Delete Image"}
                              component="span"
                              onClick={() => handleClickDeleteDocument(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {submitError && (
                  <div className="error text-safekaroDarkOrange">
                    {submitError}
                  </div>
                )}
                <Button variant="contained" type="submit">
                  submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default WorkmenCommunicationForm;
