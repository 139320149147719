import fetchInterceptor, {
  FetchOptions,
} from "../../../utils/fetchInterceptor ";
import { addIndustryEp as endpoint } from "../apiEndPoints";
import { IAddIndustry } from "../IIndustry";


const AddIndustryAPI = async ({
  header,
  industry,
  createdBy,
}: IAddIndustry) => {
  const url = endpoint();
  const options: FetchOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      header,
      industry,
      createdBy,
    }),
  };

  return fetchInterceptor(url, options);
};

export default AddIndustryAPI;
