import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { IOccupancy } from "../OccupancyType";
import AddOccupancyForm from "./AddOccupancyForm";

const AddOccupancy = () => {
  const location = useLocation();
  const data = location.state as IOccupancy;
  const title = data ? "Edit Occupancy" : "Add Occupancy";

  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title.toUpperCase()}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link
                to="/occupancy"
                className="text-addButton font-bold text-sm"
              >
                Occupancy /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
          <div className="my-2"></div>
        </Typography>
        {data ? <AddOccupancyForm propsData={data} /> : <AddOccupancyForm />}
      </Paper>
    </div>
  );
};

export default AddOccupancy;
