import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { postReqPayload } from "../NatureOfWorkTypes";
import { postNatureOfWorkEp as endpoint } from "../ApiEndPoints";

const AddNatureOfWorkAPI = async ({
    natureOfWork,createdBy,header
}: postReqPayload) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify({
            natureOfWork,createdBy
        }),
    }

    return fetchInterceptor(url, options)


};

export default AddNatureOfWorkAPI;