import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editIndustryEp as endpoint } from "../apiEndPoints";
import { IEditIndustry } from "../IIndustry";


const EditIndustryAPI = async ({ header,industry,id,isActive }: IEditIndustry) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
          industry,isActive
        }),
    }

    return fetchInterceptor(url, options)

};

export default EditIndustryAPI;