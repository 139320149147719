import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { IIndustry } from "./AddIndustry/IIndustry";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import GetAllIndustryService from "../../../api/Industry/GetAllIndustry/GetAllIndustryService";
import { header } from "../../../context/constant";
import EditIndustryService from "../../../api/Industry/EditIndustry/EditIndustryService";
import { IEditIndustry } from "../../../api/Industry/IIndustry";
const Industry = () => {
  const [industryData, setIndustryData] = useState<IIndustry[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const fetchIndustry = async () => {
    try {
      const res = await GetAllIndustryService({ header });
      if (res.status.toLowerCase() === "success") {
        setIndustryData(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchIndustry();
  }, []);

  const handleClickEditIndustry = (row: IIndustry) => {
    navigate("/industry/edit", { state: row });
  };

  const editStatus = async (row: IIndustry) => {
    const payload: IEditIndustry = {
      id: row._id!,
      isActive: false,
      header,
    };
    try {
      const res = await EditIndustryService(payload);
      if (res.status.toLowerCase() === "success") {
        const filterData = industryData.filter((ele) => ele._id !== row._id);
        setIndustryData([...filterData]);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const columns = useMemo<MRT_ColumnDef<IIndustry>[]>(
    () => [
      {
        accessorKey: "industry",
        header: "Industry",
        size: 200,
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        size: 200,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },
      {
        header: "Status",
        accessorKey: "isActive",
        size: 50,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
    []
  );

  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          Industry Table
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard{"/"}
              </Link>

              <span className="text-grey-600 text-sm"> Industry</span>
            </div>
            <Link to="/industry/add">
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
              >
                Add Industry
              </Button>
            </Link>
          </div>
        </Typography>
        <MaterialReactTable
          state={{ pagination }}
          columns={columns}
          data={industryData || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit Investigation"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit Investigation"}
                  component="span"
                  onClick={() => {
                    handleClickEditIndustry(row.original);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Change Status"}>
                <IconButton
                  color="error"
                  aria-label={"Change Status"}
                  component="span"
                  onClick={() => {
                    editStatus(row.original);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          )}
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};

export default Industry;
