import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { createTaskEp as Endpoint } from "../apiEndPoints";
import { IAddTask } from "../ITask";

const AddTaskAPI = async ({ header, relationshipManagerId, employeeId, investigationId, areaId }: IAddTask) => {
  const url = Endpoint()
  const options: FetchOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      relationshipManagerId, employeeId, investigationId, areaId
    })
  }
  return fetchInterceptor(url, options)

};

export default AddTaskAPI;