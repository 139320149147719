import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getAllProductEp as endpoint } from "../apiEndpoints";
import { GetAllProduct } from "../getProductsTypes";

const GetAllProductAPI = async ({ header }: GetAllProduct) => {
  const url = endpoint()
  const options: FetchOptions= {
    method: "GET",
    headers: header,
  }
  return fetchInterceptor(url, options)

};

export default GetAllProductAPI;
