
import { IEditTask } from "../ITask";
import EditTaskAPI from "./EditTaskAPI";

const EditTaskService = async ({ header, id,investigationId,areaId,employeeId,relationshipManagerId,isActive }: IEditTask): Promise<any> => {
    try {
        const res = await EditTaskAPI({
            header, id,investigationId,areaId,employeeId,relationshipManagerId,isActive

        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditTaskService;