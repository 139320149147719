import { IEditIndustry } from "../IIndustry";

import EditIndustryAPI from "./EditIndustryAPI";

const EditIndustryService = async ({ header, industry, id, isActive }: IEditIndustry): Promise<any> => {
    try {
        const res = await EditIndustryAPI({
            header, industry, id, isActive
        });
        return res;
    } catch (error) {
        throw error
    }
};

export default EditIndustryService;