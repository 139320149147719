
import { IGetInvestigationProps } from "../Iinvestigation";
import GetAllInvestigationAPI from "./GetAllInvestigationAPI";


const GetAllInvestigationService = async ({ header }: IGetInvestigationProps): Promise<any> => {
    try {
        const res = await GetAllInvestigationAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetAllInvestigationService;