import { IAddInvestigation } from "../Iinvestigation";
import AddInvestigationAPI from "./AddInvestigationAPI";


const AddInvestigationService = async ({ header, createdBy, investigation }: IAddInvestigation): Promise<any> => {
    try {
        const res = await AddInvestigationAPI({
            header, createdBy, investigation
        });
        return res;
    } catch (error) {
        throw error
    }
};

export default AddInvestigationService;