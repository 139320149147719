import { editReqPayload } from "../OccupancyTypes";
import EditOccupancyAPI from "./EditOccupancyAPI";
const EditOccupancyService = async ({
    occupancy, updatedBy, header, id
}: editReqPayload): Promise<any> => {
    try {
        const res = await EditOccupancyAPI({
            header: header,
            occupancy, updatedBy, id
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditOccupancyService;