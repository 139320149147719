import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editInvestigationByIdEp as endpoint } from "../apiEndpoints";
import {  IEditInvestigation } from "../Iinvestigation";


const EditInvestigationAPI = async ({ header,investigation,createdBy,id,isActive }: IEditInvestigation) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
          investigation,createdBy,isActive
        }),
    }

    return fetchInterceptor(url, options)

};

export default EditInvestigationAPI;