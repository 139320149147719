
import { IAddTask } from "../ITask";
import AddTaskAPI from "./AddTaskAPI";


const AddTaskService = async ({ header, relationshipManagerId,employeeId,investigationId,areaId}:IAddTask): Promise<any> => {
    try {
        const res = AddTaskAPI({
            header: header,
            relationshipManagerId,employeeId,investigationId,areaId
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default AddTaskService;