import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editReqPayload } from "../OccupancyTypes";
import { editOccupancyEp as endpoint } from "../ApiEndPoints";

const EditOccupancyAPI = async ({
    occupancy, updatedBy, header, id
}: editReqPayload) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
            occupancy, updatedBy
        }),
    }
    return fetchInterceptor(url, options)
};

export default EditOccupancyAPI;